import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import MadDog3 from "../../components/MadDog3"

const Maddog = () => (
  <Layout>
    <SEO title="HaroldRichter.art" />
    <MadDog3/>
  </Layout>
)

export default Maddog
