import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import '../styles/comic.scss'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const MadDog3 = () => {
  const [index, setIndex] = useState(0);
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(
        sort: { fields: name, order: ASC }
        filter:{ relativePath: {regex: "/maddog/issue3/"}, extension:{regex:"/(jpeg)/"},  sourceInstanceName:{eq:"images"}}
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const length = allFile.edges.length - 1
  const handleNext = () =>
    index === length ? setIndex(0) : setIndex(index + 1)
  const handlePrevious = () =>
    index === 0 ? setIndex(length) : setIndex(index - 1)
  const { node } = allFile.edges[index]

    return (
      <div className="comic">
        {index === 0 ? <button className="hidden">&lt;</button> : <button onClick={() => handlePrevious()}>&lt;</button>}
        <div className="image-window">
          <Img
            fluid={node.childImageSharp.fluid}
            key={node.id}
            alt={node.name.replace(/-/g, ' ').substring(2)}
          />
        </div>
        <button onClick={() => handleNext()}>&gt;</button>
      </div>
    )
}

export default MadDog3;
